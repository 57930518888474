var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "mx-auto d-flex justify-center flex-wrap align-center pa-4",
      staticStyle: { "max-width": "280px" },
      attrs: { flat: "" }
    },
    [
      _vm._l(_vm.items, function(item, index) {
        return [
          _c(
            "v-btn",
            {
              key: index,
              staticClass: "mx-3 my-2",
              attrs: { fab: "" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.$emit("dtmf", item.digit)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "d-flex flex-column" },
                [
                  _vm.$_.get(item, "icon", "")
                    ? _c("v-icon", { class: item.class }, [
                        _vm._v(" " + _vm._s(item.icon) + " ")
                      ])
                    : _c("span", { staticClass: "mb-n1", class: item.class }, [
                        _vm._v(_vm._s(item.digit))
                      ]),
                  _c("span", {
                    staticClass: "text-lighten grey--text text-caption",
                    domProps: { innerHTML: _vm._s(item.text) }
                  })
                ],
                1
              )
            ]
          )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }