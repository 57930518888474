<template>
    <v-card
        flat
        class="mx-auto d-flex justify-center flex-wrap align-center pa-4"
        style="max-width: 280px"
    >
        <template v-for="(item, index) in items">
            <v-btn
                fab
                :key="index"
                class="mx-3 my-2"
                @click.stop="$emit('dtmf', item.digit)"
            >
                <div class="d-flex flex-column">
                    <v-icon v-if="$_.get(item, 'icon', '')" :class="item.class">
                        {{ item.icon }}
                    </v-icon>
                    <span v-else class="mb-n1" :class="item.class">{{
                        item.digit
                    }}</span>
                    <span
                        class="text-lighten grey--text text-caption"
                        v-html="item.text"
                    >
                    </span>
                </div>
            </v-btn>
        </template>
    </v-card>
</template>
<script>
export default {
    name: "phone-dialer",
    components: {},
    props: {},
    data() {
        return {
            items: [
                {
                    digit: "1",
                    text: "&nbsp;",
                    class: "text-h5",
                },
                {
                    digit: "2",
                    text: "ABC",
                    class: "text-h5",
                },
                {
                    digit: "3",
                    text: "DEF",
                    class: "text-h5",
                },
                {
                    digit: "4",
                    text: "GHI",
                    class: "text-h5",
                },
                {
                    digit: "5",
                    text: "JKL",
                    class: "text-h5",
                },
                {
                    digit: "6",
                    text: "MNO",
                    class: "text-h5",
                },
                {
                    digit: "7",
                    text: "PQRS",
                    class: "text-h5",
                },
                {
                    digit: "8",
                    text: "TUV",
                    class: "text-h5",
                },
                {
                    digit: "9",
                    text: "WXYZ",
                    class: "text-h5",
                },
                {
                    digit: "*",
                    text: "&nbsp;",
                    icon: "mdi-asterisk",
                    class:
                        "text-h6 font-weight-light mt-1 grey--text text--darken-2",
                },
                {
                    digit: "0",
                    text: "+",
                    class: "text-h5",
                },
                {
                    digit: "#",
                    text: "&nbsp;",
                    class: "text-h5",
                },
            ],
        };
    },
    computed: {},

    methods: {},
};
</script>

<style scoped>
</style>